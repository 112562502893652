@import "~react-image-gallery/styles/css/image-gallery.css";

/* Background Colors */

.inesc-bg-md-green {
    background-color: #00A776;
}

.inesc-bg-dk-green {
    background-color: #003828;
}

.inesc-border-dk-green {
    border-bottom: 0.5vh solid #003828;
}

.inesc-border-md-green {
    border-top: 0.5vh solid #00A776;
}

.navbar-shadow {
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
}


#navbarToggle .navbar-nav .nav-link {
    color: #ffffff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 20px;
}

/********************Contact**************/

.addPatient-Submit-Button {
    min-width: fit-content!important;
}

/****************************************/

/*********************Header**********************/

.content-wrapper {
    flex: 1;
    min-height: 89vh;
}

.nav-link {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff !important;
    font-size: 20px !important;
}

.hover-underline-animation {
    display: inline-block;
    position: relative;
}

.hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    transform-origin: bottom center;
    transition: transform 0.45s;
}

.hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom center;
}

@media (max-width: 850px) {

    .navbar-nav .dropdown-menu {
        position: static;
        border: none;
        background-color: transparent;
    }

    .dropdown-item-white {
        color: white !important;

    }
    .dropdown-item {
        color: white;
    }

    .hover-underline-animation::after {
        height: 0px;
    }
}

/****************************************/

/****************BODY********************/

.App {
    height: 100vh;
}

body {
    /*background-image: url(../public/assets/InescTec.png);*/
    background-color: #f6f6f631;
    color: #333333;
}

.main-body {
    padding-top: 5rem;
    width: 100% !important;
    padding-left: 0% !important;
    margin-left: 0% !important;
    padding-right: 0% !important;
    margin-right: 0% !important;
    max-width: 100%;
    min-width: 100%;
}


/***LogIn Page****/
.login-form-container {
    background-color: #ffffff;
    top: 10vh;
    max-width: 600px;
    width: 90%;
    height: auto;
    position: relative;
    text-align: center;
    margin: 10px auto;
    padding: 20px;
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.login-form-container span {
    font-weight: bold;
    font-size: 1.5rem;
    color: #555;
    margin-top: 10px;
    display: block;
}

.loginForm {
    margin: auto;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr; /* Single column for smaller screens */
    gap: 15px;
}

.authenticate input {
    margin-bottom: 15px;
    height: 40px;
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.authenticate-Button {
    height: auto;
    width: 100%; /* Responsive width */
    padding: 10px 0;
    background-color: #00A776 !important;
    border-color: #003828 !important;
    font-size: 1rem; /* Responsive font size */
    margin-bottom: 10px;
}

.authenticate-Button:hover {
    background-color: #003828 !important;
}

.registerLinks {
    margin-top: 20px;
    width: 100%;
    text-align: center;
}

.registerLinks p {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1rem;
    margin-bottom: 10px;
}

.registerLinks a {
    text-decoration: none !important;
    color: blue !important;
}

.registerLinks a:hover {
    cursor: pointer !important;
    text-decoration: underline;
}

/***Login Page Responsive****/
@media (max-width: 800px) {
    .login-form-container {
        top: 0;
        width: 95%;
        padding: 15px;
        box-shadow: none;
    }

    .loginForm {
        display: flex;
        flex-direction: column;
    }

    .authenticate input {
        width: 100%;
    }

    .authenticate-Button {
        width: 100%;
    }

    .registerLinks {
        padding-top: 10px;
        font-size: 14px;
    }
}


/*****/

/****Catalogue****/
@media (max-width: 800px) {
    .responsiveImageCard{
        width:fit-content!important;
    }
}

/*****/

/****Register Page****/
.register-form-container {
    background-color: #ffff;
    width: 60vw;
    height: 80vh;
    position: relative;
    text-align: center;
    overflow: auto;
    margin: auto;
}

.register-form-container span {
    font-weight: bold;
    font-size: larger;
    color: #555;
    margin-top: 5px;
    display: inline-block;
}

.registerForm {
    height: 95%;
    width: 100%;
}

.register {
    text-align: left !important;
    width: 90%;
    height: 80%;
    padding-top: 1%;
    padding-left: auto;
    margin: auto;
}

.register-Button {
    height: 40px;
    width: 100%;
    background-color: #00A776 !important;
    border-color: #003828 !important;
    margin-bottom: 5px;
}

.register-Button:hover {
    background-color: #003828 !important;
}

/********/

/****Register Page Responsive****/

@media (max-width: 800px) {

    .register-form-container {
        width: 100vw;
        box-shadow: none;
        margin: auto;
    }

    .text-muted {
        font-size: 3.0vw!important;
    }
    
}
        
/********/

/****Patients Page****/
.content-container {
    background-color: #fff;
    padding-left: 20px !important;
    margin: auto;
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.1);
    overflow-y: auto !important;

}

.addPatient-div {
    text-align: right;
    margin-bottom: 15px;
    margin-top: 10px;
}

.addPatient-button {
    color: #00A776 !important;
    border-color: #00A776 !important;
    border-width: 2px !important;
    border-radius: 4px !important;
}

.addPatient-button:hover {
    background-color: #00A776 !important;
    color: #fff !important;
}

.card {
    width: 85%;
}

.card-footer {
    text-align: right;
}

.card-footer-text {
    font-weight: bold;
}

.card-header-text {
    color: black;
    text-decoration: none;
}

.card-header-text:hover {
    color: rgb(62, 114, 224);
    font-weight: bolder;
    cursor: pointer;
}

.badge-container {
    padding: 10px;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .badge-container h2 {
    word-wrap: break-word; /*older browsers */
    overflow-wrap: break-word; /*newer browsers */
  }
  
  .badge-container .badge {
    display: block;
    max-width: 100%;
  }

@media (max-width: 800px) {

    .card-footer-text {
      display: block;
      margin-bottom: 5px; 
    } 

    .badge{
        white-space: pre-wrap!important;
    }

    .searchPatientContainer * {
        width: 100%!important;
        margin-bottom: 13px;
    }

  }

/********/

/****Patient Profile Page***/

.patientContainer{
     display: grid;
     grid-template-columns: 30% 70%;
     margin-right: 2em!important;
     margin-top: 2em!important;
}

.add-moments-button {
    width: 15m; /* Adjust width as needed */
}
  

.patientPageButtons{
    display: flex!important;
    justify-content: end;
}

.patientPageButtons * {
    margin-left: 0.3em;
}

.btn.show {
    background-color: transparent;
    border-color: var(--bs-btn-active-border-color);
}

@media (max-width: 850px) {
    .patientOption-button{
        margin-bottom: 0.5em;
        width: 15em!important;
    }
    
    .patientPageButtons{
        display: block!important;
    }
}


/********/

/****AddPatient Page****/
.addPatient-form-container {
    background-color: #ffff;
    width: 70vw;
    height: auto;
    position: relative;
    text-align: center;
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.1);
    margin: auto;
}

.addPatient-form-container span {
    font-weight: bold;
    font-size: 3.5vh;
    color: #555;
    margin-top: 5px;
    display: inline-block;
}

.addPatientForm {
    height: auto;
    width: 100%;
}

.addPatient {
    text-align: left !important;
    width: 90%;
    height: 80%;
    padding-top: 1%;
    padding-left: auto;
    margin: auto;
}

.addPatient-Submit-Button {
    height: 5vh;
    width: 10vw;
    background-color: #00A776 !important;
    border-color: #003828 !important;
    margin-bottom: 5px;
}

.addPatient-Submit-Button:hover {
    background-color: #003828 !important;
}

.deletePatient-button {
    color: white !important;
    background-color: rgb(255, 62, 62) !important;
    border-color: rgb(255, 62, 62) !important;
    border-width: 2px !important;
    border-radius: 4px !important;
}

@media screen and (max-width: 850px) {
    .patientContainer {
        display: block;
        margin-bottom: 1rem!important;
    }

    .patientCard{
        width: fit-content!important;
    }
}
/********/


.error-feedback {
    color: red;
}

/******Patient Photos Page*******/

.myImages {
    /*border-radius: 5px;*/
    cursor: pointer;
    transition: 0.3s;
    margin-top: 5px;
    text-align: center;
}

.myImages:hover {
    /*opacity: 0.7;*/
    transform: scale(1.2);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}


@media screen and (max-width: 768px) {

    .patientCard{
        width: fit-content!important;
    }

    .FilledButton{
        width: fit-content!important;
    }
}

/********************************/

/****Team Create Page****/

@media screen and (max-width: 768px) {

    .content-container{
        box-shadow: none;
    }
    
}

/********************************/

/****Teams Page****/

.teamCard{
    width: 17vw!important; 
    height: auto!important;
}

/* Mobile Phone */
@media screen and (max-width: 768px) {
    .teamCard{
        width:70vw!important;
    }
}

/* IPad Air and iPad Mini in portrait */
@media only screen and (min-device-width: 834px) and (max-device-width: 900px) and (orientation: portrait) {
    .teamCard{
        width: 25vw!important;
    }
  }

/* Ipads Pro in Portrait mode */
@media only screen and  (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {
    .teamCard{
        width:20vw!important;
    }
  }
/********************************/

/****Team Details Page****/  

    .team-logo-image {
        max-width: 70%;
        height: auto;
    }

  /* Centering the FileUploader in smaller screens */
  @media (max-width: 768px) {
    .file-uploader-container {
      text-align: center;
    }

    .team-logo-image {
        max-width: 100%;
        height: auto;
    }
  }

/********************************/

/******Cinderella Page******/

input[type=range] {
    height: 25px;
    -webkit-appearance: none;
    width: 100%;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #000000;
    background: #51a1b5;
    border-radius: 25px;
    border: 0px solid #000101;
}

input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #000000;
    height: 20px;
    width: 30px;
    border-radius: 7px;
    background: #2c0065;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -3.5px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #51a1b5;
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 13px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #000000;
    background: #51a1b5;
    border-radius: 25px;
    border: 0px solid #000101;
}

input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #000000;
    height: 20px;
    width: 39px;
    border-radius: 7px;
    background: #2c0065;
    cursor: pointer;
}

input[type=range]::-ms-track {
    width: 100%;
    height: 13px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type=range]::-ms-fill-lower {
    background: #51a1b5;
    border: 0px solid #000101;
    border-radius: 50px;
    box-shadow: 0px 0px 0px #000000;
}

input[type=range]::-ms-fill-upper {
    background: #51a1b5;
    border: 0px solid #000101;
    border-radius: 50px;
    box-shadow: 0px 0px 0px #000000;
}

input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #000000;
    height: 20px;
    width: 39px;
    border-radius: 7px;
    background: #2c0065;
    cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
    background: #51a1b5;
}

input[type=range]:focus::-ms-fill-upper {
    background: #51a1b5;
}

@media screen and (max-width: 768px) {
    .slidecontainer {
        padding: 15px 0px !important;
        margin-bottom: 15px;
    }

    .slidecontainer p {
        padding: 0px !important;
        width: 100%;
    }

    .responsiveRow{
        display: block!important;
    }
}


/***************************/

/************Dashboard Page***************/

.table-instnum-link:hover {
    cursor: pointer;
}

.formPatientInput {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}

.formPatientInput:focus {
    outline:none;
    border-color: #2684FF!important; /* The blue color used by react-select */
    box-shadow: 0 0 0 1px #2684FF, 0 0 0 4px rgba(38, 132, 255, 0.25); /* Similar to react-select focus shadow */
}

.dashboardButtons{
    display:flex;
}

.dashboardButtons * {
    margin-left: 0.3em;
}

.toggleBtn {
    text-decoration: none !important;
    color: #827F80 !important;
    font-size: 1.2rem !important;
    font-weight: 600 !important;
}

.custom-select {
    color:  rgba(33, 37, 41, 0.7)!important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}

.custom-select option {
    color:  rgba(33, 37, 41, 0.7)!important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}


/*****************************************/


/***********WebBCCTCorePage***************/
.webbcct-container {
    width: 100% !important;
    padding-left: 0% !important;
    margin-left: 0% !important;
    padding-right: 0% !important;
    margin-right: 0% !important;
    max-width: 100%;
    min-width: 100%;
    overflow-x: hidden;
}

.webbcct-upload-container {
    width: 20% !important;
}

.webbcct-uploaded-images-container {
    max-height: 50vw;
}

.webbcct-main-container {
    width: 80%;
}

.Collapsible {
    background: white;
}

.Collapsible__trigger {
    background: #00A776;
    border-radius: 5px;
    border: 1px solid #003828;
    padding: 10px;
    color: white;
}

.CustomTriggerCSS {
    background-color: lightcoral;
    transition: background-color 200ms ease;
}

/*****************************************/


/******General********/

.OutlineButton {
    background-color: white !important;
    color: #00A776 !important;
    border-color: #00A776 !important;
    border-width: 2px !important;
    border-radius: 4px !important;
}

.OutlineButton:hover {
    background-color: #00A776 !important;
    color: #fff !important;
}

.FilledButton {
    width: fit-content;
    background-color: #00A776 !important;
    border-color: #003828 !important;
    margin-bottom: 5px;
}

.FilledButton:hover {
    background-color: #003828 !important;
}

.OutlineDangerButton {
    border-width: 2px !important;
    border-radius: 4px !important;
}

.PageTitle {
    font-weight: bold;
    font-size: 3.5vh;
    color: #555;
    margin-top: 5px;
    display: inline-block;
}

.searchContainer {
    width: 100%;
    align-items: center;
}

.roundButton {
    border-radius: 50px !important;
    background-color: #00A776 !important;
    border-color: #003828 !important;
    height: fit-content;
}

/****************************************/

/***************FOOTER*******************/

.site-footer {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
}
 
  .navbar-toggler {
    font-size: 1.5rem;
    border: none !important;
    outline: none !important;
    box-shadow: none !important; 
  }

.main-content {
    flex: 1; /* Pushes the footer to the bottom */
}

  @media (min-width: 992px) {
    .ml-auto {
      margin-left: auto !important;
    }
  }
  
/****************************************/

/**********MORPHING PAGE******************/

.morphingButton{
    width: 10vw!important;
}

.cardMorphing:hover {
    background-color: gray;
    border-width: 5px;
    transform: scale(1.1);
}

@media screen and (max-width: 768px) {

    .morphingButton{
        width: fit-content!important;
    }

    .queryCardMorphing,
    .cardMorphing{
        width: fit-content!important;
    }

}

/****************************************/

.cardImage {
    min-height: 250px;
    max-height: 250px;
    min-width: 250px;
    max-width: 250px;
}

.nav-tabs .nav-link {
    background-color: lightgrey !important;
    color: black !important;
    border: solid lightgrey !important;
    border-top-left-radius: 0em!important;
    border-top-right-radius: 0em!important;
}

.nav-tabs .nav-link.active {
    border: solid 4px gray !important;
    background-color: gray !important;
    font-weight: bold;
}

#fullpage {
    display: none;
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: contain;
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    background-color: rgb(211, 211, 211, 0.6);
}
